import React, { useState, useEffect } from "react";
import { Box, Button,RadioGroup,Radio,FormControlLabel,FormLabel,Select,
  FormHelperText,MenuItem,FormControl,InputLabel, Typography, useTheme, TextField, 
  Dialog, DialogTitle, DialogContent, DialogActions,IconButton,Grid,Avatar } from "@mui/material";

import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Link } from 'react-router-dom';
import { message } from 'antd';
import PersonIcon from '@mui/icons-material/Person'; 
import EditIcon from "@mui/icons-material/Edit"; 
import SearchIcon from "@mui/icons-material/Search";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useSelector, useDispatch } from "react-redux";
import { fetchPackages } from "../../redux/packegSlice"; 
import { fetchGroups } from "../../redux/groupSlice"; 
import { fetchAgents } from "../../redux/agentsSlice"; 
import InputBase from "@mui/material/InputBase";
import { getUmrahDetailsList, deleteUmrahDetails, updateUmrahDetails } from "../../apicalls/umrahDetails";
import DeleteIcon from "@mui/icons-material/Delete"; 
import PaymentIcon from '@mui/icons-material/Payment'; 
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
const Team = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user } = useSelector((state) => state.users); 
  const vendorId = user ? user._id : ""; 
  const [umrahData, setUmrahData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [updateModalPaymentOpen, setUpdateModalPaymentOpen] = useState(false);
  const [showPaymentData, setShowPaymentData] = useState(false);

  const [currentUmrahId, setCurrentUmrahId] = useState(null);
  const [currentData, setCurrentData] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const { packages } = useSelector((state) => state.packages || []);
  const { groups } = useSelector((state) => state.groups || []);
  const { agents } = useSelector((state) => state.agents || []); 
  const [openInfoDialog, setOpenInfoDialog] = useState(false); 
  const [selectedPrehajj, setSelectedPrehajj] = useState(null); 
  const dispatch = useDispatch();
  const typeOf = 'Hajjmain'; 
  const [touched, setTouched] = useState({
    paidAmount: false,
    paymentDate: false,
  });

  const handleFieldBlur = (field) => {
    setTouched((prev) => ({ ...prev, [field]: true }));
  };
  useEffect(() => {
      if (vendorId) {
          dispatch(fetchPackages(vendorId, typeOf));
          dispatch(fetchGroups(vendorId, typeOf));
          dispatch(fetchAgents(vendorId));
      }
  }, [dispatch, vendorId, typeOf]); 


  const fetchData = async () => {
    setLoading(true);
    try {
      const type = "Hajjmain";
      const response = await getUmrahDetailsList(vendorId, type);
      if (response.success) {
        setUmrahData(response.data);
      } else {
        message.error(response.message);
      }
    } catch (error) {
      message.error("Failed to fetch umrah data");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await deleteUmrahDetails(id);
      if (response.success) {
        message.success("Hajj Main deleted successfully");
        fetchData();
      } else {
        message.error(response.message);
      }
    } catch (error) {
      message.error("Failed to delete umrah");
      console.error(error);
    }
  };

  const openDeleteConfirmation = (umrah) => {

    if (umrah.status === "Paid") {
      message.warning("This record has already been processed for payment and cannot be deleted.");
      return;
    }

    if (umrah.status === "Completed") {
      message.warning("This record has already been processed for payment and cannot be deleted.")
      return;
    }
    setCurrentUmrahId(umrah._id);
    setDeleteModalOpen(true);
  };

  const confirmDelete = () => {
    if (currentUmrahId) {
      handleDelete(currentUmrahId);
      setDeleteModalOpen(false);
      setCurrentUmrahId(null);
    }
  };

  const filteredData= umrahData.filter(umrah => {
    return (
      umrah.umrahName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      umrah.fatherName.includes(searchQuery) ||
      umrah.motherName.toLowerCase().includes(searchQuery.toLowerCase())
    );

  });

  const openUpdateDialogHandler = (umrah) => {
    if (umrah.status === "Paid") {
      message.warning("This record has already been processed for payment and cannot be updated.");
      return;
    }

    if (umrah.status === "Completed") {
      message.warning("This record has already been processed for payment and cannot be updated.")
      return;
    }
    setCurrentData({
      ...umrah,
      Agent_id: umrah.Agent_id?._id || '', 
    });
    setUpdateModalOpen(true);
  };
  

  const handleUpdate = async () => {
    try {
      const response = await updateUmrahDetails(currentData._id, currentData);
      if (response.success) {
        message.success("Hajj Main  updated successfully");
        fetchData();
        setUpdateModalOpen(false);
        setUpdateModalPaymentOpen(false); 
        setCurrentData({});
      } else {
        message.error(response.message);
      }
    } catch (error) {
      message.error("Failed to update umrah");
      console.error(error);
    }
  };


  const handleViewPdf = async (selectedPrehajj) => {
    const doc = new jsPDF();

    // Header Section (Organization Info and Logo)
    doc.setFontSize(16);
    doc.setFont("helvetica", "bold");
    doc.text("Airbook Service Organization", 105, 10, null, null, 'center');
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text("123 Main Street, City, Country | Phone: +123-456-7890 | Email: info@airbook.com", 105, 16, null, null, 'center');

    if (selectedPrehajj.logoUrl) {
        const logo = new Image();
        logo.src = selectedPrehajj.logoUrl;
        await new Promise((resolve) => {
            logo.onload = () => {
                doc.addImage(logo, 'JPEG', 10, 5, 20, 20);
                resolve();
            };
        });
    }

    doc.setLineWidth(0.5);
    doc.line(10, 20, 200, 20);

    // Receipt Title
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text("Application Receipt", 105, 30, null, null, 'center');
    doc.setFontSize(10);
    doc.text(`Receipt No.: ${selectedPrehajj.receiptNo}`, 10, 40);
    doc.text(`Date: ${new Date().toLocaleDateString()}`, 200, 40, null, null, 'right');

    // Recipient Details Section
    let yPosition = 50;
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text("Recipient Details:", 10, yPosition);
    yPosition += 6;
    doc.text(`Full Name: ${selectedPrehajj.umrahName}`, 10, yPosition);
    yPosition += 6;
    doc.text(`Father's Name: ${selectedPrehajj.fatherName}`, 10, yPosition);
    yPosition += 6;
    doc.text(`Mother's Name: ${selectedPrehajj.motherName}`, 10, yPosition);
    yPosition += 6;
    doc.text(`Phone No.: ${selectedPrehajj.mobileNumber}`, 10, yPosition);
    yPosition += 6;
    doc.text(`Passport No.: ${selectedPrehajj.passportNumber}`, 10, yPosition);
    yPosition += 6;
    doc.text(`Address: ${selectedPrehajj.address}`, 10, yPosition);
    yPosition += 6;

    // Application Details Section
    yPosition += 10;
    doc.setFontSize(12);
    doc.text("Application Details:", 10, yPosition);
    yPosition += 6;
    doc.text(`Agent Name: ${selectedPrehajj.Agent_id ? selectedPrehajj.Agent_id.AgentName : 'N/A'}`, 10, yPosition);
    yPosition += 6;
    doc.text(`Year: ${selectedPrehajj.year}`, 10, yPosition);
    yPosition += 6;
    doc.text(`NID No.: ${selectedPrehajj.nidNo}`, 10, yPosition);
    yPosition += 6;
    doc.text(`Charge Amount: ${selectedPrehajj.billAmount}`, 10, yPosition);
    yPosition += 6;
    doc.text(`Hajj Main-Registration Date: ${new Date(selectedPrehajj.registrationDate).toLocaleDateString()}`, 10, yPosition);
    yPosition += 6;
    doc.text(`Date of Birth: ${new Date(selectedPrehajj.dateOfBirth).toLocaleDateString()}`, 10, yPosition);
    yPosition += 6;
    doc.text(`Serial No.: ${selectedPrehajj.serialNo}`, 10, yPosition);
    yPosition += 6;
    doc.text(`Tracking No.: ${selectedPrehajj.trackingNo}`, 10, yPosition);
    yPosition += 6;
    doc.text(`Payment Status: ${selectedPrehajj.status}`, 10, yPosition);
    yPosition += 6;

    // Remarks Section
    if (selectedPrehajj.remark) {
        yPosition += 10;
        doc.setFontSize(12);
        doc.text("Remarks:", 10, yPosition);
        yPosition += 6;
        doc.setFontSize(10);
        doc.text(selectedPrehajj.remark, 10, yPosition);
        yPosition += 6;
    }

    // Footer Section
    yPosition += 20;
    doc.setLineWidth(0.5);
    doc.line(10, yPosition, 200, yPosition);
    yPosition += 6;
    doc.setFontSize(10);
    doc.setFont("helvetica", "italic");
    doc.text("Thank you for your application!", 105, yPosition, null, null, 'center');
    yPosition += 6;
    doc.text("For support, contact us at +123-456-7890 or info@airbook.com", 105, yPosition, null, null, 'center');

    // Save PDF
    doc.save(`application-receipt-${selectedPrehajj.umrahName}.pdf`);
};

  
  const handleViewDetails = (prehajj) => {
    setSelectedPrehajj(prehajj); 
    setOpenInfoDialog(true); 
};

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const handleCloseInfoDialog = () => {
    setOpenInfoDialog(false); 
};

const handleMakePayment = (umrah) => {
  setCurrentData({
    ...umrah,
    Agent_id: umrah.Agent_id?._id || '', 
    
  });
  setUpdateModalPaymentOpen(true);
};

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
      <Header title="HAJJ MAIN-REGISTER" subtitle="Managing the Main-Registration List" />
        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }} className="text-white"
          >
            <Link to="/hajjmaincreate" className="text-decoration-none text-white">
            + Add New Main Haji Registration
          </Link>
          </Button>
        </Box>
      </Box>
 {/* Search Box positioned to the right of the table */}
 
 <Box
      display="flex"
      justifyContent="flex-end"  
      sx={{
        padding: "5px 10px",  
        width: "auto", 
      }}
    >
      <Box
        display="flex"  
        backgroundColor={colors.primary[400]} 
        borderRadius="4px"
        sx={{
          width: 250, 
          padding: "5px 10px",
        }}
      >
        <InputBase
          sx={{
            flex: 1,  
            borderRadius: "4px",
            padding: "5px 10px", 
          }}
          placeholder="Search Hajj Main-Registration"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box>
    </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": { border: "none" },
          "& .MuiDataGrid-cell": { borderBottom: "none" },
          "& .name-column--cell": { color: colors.greenAccent[300] },
          "& .MuiDataGrid-columnHeaders": { backgroundColor: colors.blueAccent[700], borderBottom: "none" },
          "& .MuiDataGrid-virtualScroller": { backgroundColor: colors.primary[400] },
          "& .MuiDataGrid-footerContainer": { borderTop: "none", backgroundColor: colors.blueAccent[700] },
          "& .MuiCheckbox-root": { color: `${colors.greenAccent[200]} !important` },
        }}
      >
        <div className="table-responsive">
          <table className="table accordion table-dark">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Full Name</th>
                <th scope="col">Agent Name</th>
                <th scope="col">Father's Name</th>
                <th scope="col">Mother's Name</th>
                <th scope="col">Year</th>
                <th scope="col">NID No.</th>
                <th scope="col">Passport No.</th>
                <th scope="col">Phone No.</th>
                <th scope="col">Hajj Main-Registration Date</th>
                <th scope="col">Date of Birth</th>
                <th scope="col">Serial No.</th>
                <th scope="col">Address</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 ? filteredData.map((umrah, index) => (
                <React.Fragment key={index}>
                  <tr data-bs-toggle="collapse" data-bs-target={`#row${index}`}>
                    <th scope="row">+</th>
                    <td>{umrah.umrahName}</td>
                    <td>{umrah.Agent_id && umrah.Agent_id.AgentName ? umrah.Agent_id.AgentName : 'N/A'}</td>
                    <td>{umrah.fatherName}</td>
                    <td>{umrah.motherName}</td>
                    <td>{umrah.year}</td>
                    <td>{umrah.nidNo}</td>
                    <td>{umrah.passportNumber}</td>
                    <td>{umrah.mobileNumber}</td>
                    <td>{new Date(umrah.registrationDate || Date.now()).toLocaleDateString()}</td>
                    <td>{new Date(umrah.dateOfBirth || Date.now()).toLocaleDateString()}</td>
                    <td>{umrah.serialNo}</td>
                    <td>{umrah.address}</td>
                  </tr>
                  <tr className="collapse accordion-collapse" id={`row${index}`} data-bs-parent=".table">
                    <td colSpan="5">
                      <b>Tracking No.:</b> {umrah.trackingNo}<br /> 
                      <b>Reference Person:</b> {umrah.referencePerson}<br /> 
                      <b>Charge Amount:</b> {umrah.billAmount}<br />
                      <b>Remarks:</b> {umrah.remark}<br />
                      <b>Payment Status:</b> {umrah.status}<br />
                      <b>Action:</b><br />
                      <EditIcon onClick={() => openUpdateDialogHandler(umrah)} style={{ cursor: 'pointer', marginRight: 8 }} />
                      <DeleteIcon
                      onClick={() => openDeleteConfirmation(umrah)} 
                      style={{
                        cursor: 'pointer',
                        color: 'red',       
                      }}
                    />
                  
                    <VisibilityIcon
                      sx={{
                        cursor: 'pointer',
                        marginLeft: 2, 
                        color: 'blue',  
                      }}
                      onClick={() => handleViewDetails(umrah)} 
                    />
                    <PictureAsPdfIcon
                      sx={{
                        cursor: 'pointer',
                        color: 'green',  
                        marginLeft: 2   
                      }}
                      onClick={() => handleViewPdf(umrah)}  
                    />
                         <IconButton
                      sx={{
                        cursor: 'pointer',
                        color: 'blue', 
                        marginLeft: 2, 
                      }}
                      onClick={() => handleMakePayment(umrah)}
                      
                    >
                      <PaymentIcon />
                    </IconButton>
                    </td>
                  </tr>
                </React.Fragment>
              )) : (
                <tr>
                    <td colSpan="13" className="text-center">No Main-Registration data available</td>
                </tr>
            )}
            </tbody>
          </table>
        </div>
      </Box>

      {/* Delete Confirmation Modal */}
      <Dialog open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this hajj main  register?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmDelete} variant="contained" color="primary">Yes</Button>
          <Button onClick={() => setDeleteModalOpen(false)} variant="outlined" color="secondary">No</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={updateModalOpen} onClose={() => setUpdateModalOpen(false)}>
      <DialogTitle>Update Hajj-Main Register </DialogTitle>
      <DialogContent>
        {Object.keys(currentData).length > 0 && (
          <>
            <TextField
              autoFocus
              margin="dense"
              label="Full Name"
              type="text"
              fullWidth
              variant="outlined"
              value={currentData.umrahName || ''}
              onChange={(e) => setCurrentData({ ...currentData, umrahName: e.target.value })}
            />
          <FormControl fullWidth variant="filled">
              <InputLabel id="package-label">Select Agent Name</InputLabel>
              <Select
                labelId="package-label"
                value={currentData.Agent_id || ''}
                onChange={(e) => setCurrentData({ ...currentData, Agent_id: e.target.value })}
                name="Agent_id"
              >
                {agents.map(agent => (
                        <MenuItem key={agent._id} value={agent._id}>
                          {agent.AgentName} 
                        </MenuItem>
                      ))}
              </Select>
            </FormControl>
            <TextField
              margin="dense"
              label="Father's Name"
              type="text"
              fullWidth
              variant="outlined"
              value={currentData.fatherName || ''}
              onChange={(e) => setCurrentData({ ...currentData, fatherName: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Mother's Name"
              type="text"
              fullWidth
              variant="outlined"
              value={currentData.motherName || ''}
              onChange={(e) => setCurrentData({ ...currentData, motherName: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Reference Person"
              type="text"
              fullWidth
              variant="outlined"
              value={currentData.referencePerson || ''}
              onChange={(e) => setCurrentData({ ...currentData, referencePerson: e.target.value })}
            />

            <TextField
              margin="dense"
              label="Mobile No."
              type="text"
              fullWidth
              variant="outlined"
              value={currentData.mobileNumber || ''}
              onChange={(e) => setCurrentData({ ...currentData, mobileNumber: e.target.value })}
            />

          <TextField
            margin="dense"
            label="Year"
            type="number"
            fullWidth
            variant="outlined"
            value={currentData.year || ''}
            onChange={(e) => setCurrentData({ ...currentData, year: e.target.value })}
            inputProps={{ min: "1900", max: "2099", step: "1" }} // Example range for valid years
          />

           
            <TextField
              margin="dense"
              label="Registration Date"
              type="date"
              fullWidth
              variant="outlined"
              value={currentData.registrationDate ? new Date(currentData.registrationDate).toISOString().substring(0, 10) : ''}
              onChange={(e) => setCurrentData({ ...currentData, registrationDate: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Date of Birth"
              type="date"
              fullWidth
              variant="outlined"
              value={currentData.dateOfBirth ? new Date(currentData.dateOfBirth).toISOString().substring(0, 10) : ''}
              onChange={(e) => setCurrentData({ ...currentData, dateOfBirth: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Passport No."
              type="text"
              fullWidth
              variant="outlined"
              value={currentData.passportNumber || ''}
              onChange={(e) => setCurrentData({ ...currentData, passportNumber: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Charge Amount"
              type="number"
              fullWidth
              variant="outlined"
              value={currentData.billAmount || ''}
              onChange={(e) => setCurrentData({ ...currentData, billAmount: e.target.value })}
            />
             <TextField
              margin="dense"
              label="NID No"
              type="text"
              fullWidth
              variant="outlined"
              value={currentData.nidNo || ''}
              onChange={(e) => setCurrentData({ ...currentData, nidNo: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Tracking No"
              type="text"
              fullWidth
              variant="outlined"
              value={currentData.trackingNo || ''}
              onChange={(e) => setCurrentData({ ...currentData, trackingNo: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Serial No"
              type="text"
              fullWidth
              variant="outlined"
              value={currentData.serialNo || ''}
              onChange={(e) => setCurrentData({ ...currentData, serialNo: e.target.value })}
            />
           <TextField
              margin="dense"
              label="Address"
              type="text"
              fullWidth
              variant="outlined"
              value={currentData.address || ''}
              onChange={(e) => setCurrentData({ ...currentData, address: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Remarks"
              type="text"
              fullWidth
              variant="outlined"
              value={currentData.remark || ''}
              onChange={(e) => setCurrentData({ ...currentData, remark: e.target.value })}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleUpdate} variant="contained" color="success">Update</Button>
        <Button onClick={() => setUpdateModalOpen(false)} variant="outlined" color="secondary">Cancel</Button>
      </DialogActions>
    </Dialog>
  {/** Update Payment Model */}
  <Dialog open={updateModalPaymentOpen} onClose={() => setUpdateModalPaymentOpen(false)} disabled={currentData.status === "Paid"}>
        <DialogTitle>Update Payment Details</DialogTitle>
        <DialogContent>
          {Object.keys(currentData).length > 0 && (
            <>
              <FormControl fullWidth variant="filled" margin="dense">
                <InputLabel id="package-label">Payment Type</InputLabel>
                <Select
                  labelId="package-label"
                  value={currentData.paymentType || ''}
                  onChange={(e) => setCurrentData({ ...currentData, paymentType: e.target.value })}
                  name="paymentType"
                  required
                  disabled={currentData.status === "Paid"}  // Disable if paid
                >
                  <MenuItem value="Online Payment">Online Payment</MenuItem>
                  <MenuItem value="Offline Payment">Offline Payment</MenuItem>
                </Select>
              </FormControl>

              <TextField
                margin="dense"
                label="Depositer Name"
                type="text"
                fullWidth
                variant="outlined"
                value={currentData.depositerName || ''}
                onChange={(e) => setCurrentData({ ...currentData, depositerName: e.target.value })}
                required
                disabled={currentData.status === "Paid"}  // Disable if paid
              />
              <TextField
                margin="dense"
                label="Bank Name"
                type="text"
                fullWidth
                variant="outlined"
                value={currentData.bankName || ''}
                onChange={(e) => setCurrentData({ ...currentData, bankName: e.target.value })}
                required
                disabled={currentData.status === "Paid"}  // Disable if paid
              />
              <TextField
                margin="dense"
                label="Cheque No/Transaction No"
                type="text"
                fullWidth
                variant="outlined"
                value={currentData.ChequeNo || ''}
                onChange={(e) => setCurrentData({ ...currentData, ChequeNo: e.target.value })}
                required
                disabled={currentData.status === "Paid"}  // Disable if paid
              />

              {/* Payable Amount Field (Disabled) */}
              <TextField
                margin="dense"
                label="Payable Amount"
                type="number"
                fullWidth
                variant="outlined"
                value={currentData.billAmount || ''}
                disabled
              />

            {/* Paid Amount */}
            <TextField
              margin="dense"
              label="Paid Amount"
              type="number"
              fullWidth
              variant="outlined"
              value={currentData.paidAmount || ''}
              onBlur={() => handleFieldBlur('paidAmount')}
              onChange={(e) => {
                const value = parseFloat(e.target.value);
                const isValid = value === (currentData.billAmount || 0); 

                setCurrentData({
                  ...currentData,
                  paidAmount: value,
                  isPaidAmountValid: isValid, 
                });
              }}
              error={touched.paidAmount && !currentData.isPaidAmountValid && currentData.paidAmount !== undefined}
              helperText={
                touched.paidAmount && !currentData.isPaidAmountValid && currentData.paidAmount !== undefined
                  ? 'Paid Amount must be equal to Payable Amount'
                  : ''
              }
              required
              disabled={currentData.status === "Paid"}  // Disable if status is "Paid"
            />


              {/* Payment Date with Validation */}
              <TextField
                margin="dense"
                label="Payment Date"
                type="date"
                fullWidth
                variant="outlined"
                value={currentData.paymentDate ? new Date(currentData.paymentDate).toISOString().substring(0, 10) : ''}
                onBlur={() => handleFieldBlur('paymentDate')}
                onChange={(e) => {
                  const selectedDate = new Date(e.target.value);
                  const currentDate = new Date();
                  if (selectedDate <= currentDate) {
                    setCurrentData({
                      ...currentData,
                      paymentDate: e.target.value,
                      isPaymentDateValid: true,
                    });
                  } else {
                    setCurrentData({
                      ...currentData,
                      isPaymentDateValid: false,
                    });
                  }
                }}
                error={touched.paymentDate && !currentData.isPaymentDateValid}
                helperText={touched.paymentDate && !currentData.isPaymentDateValid ? 'Payment Date cannot be in the future' : ''}
                required
                disabled={currentData.status === "Paid"}  // Disable if paid
              />

              {/* Status Radio Buttons */}
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">Status</FormLabel>
                <RadioGroup
                  row
                  value={currentData.status || ''}
                  onChange={(e) => setCurrentData({ ...currentData, status: e.target.value })}
                  disabled={currentData.status === "Paid"}  // Disable if paid
                >
                  <FormControlLabel value="Unpaid" control={<Radio />} label="Unpaid" disabled />
                  <FormControlLabel value="Completed" control={<Radio />} label="Completed"  disabled={currentData.status === "Paid"}/>
                  {/* Show the "Paid" radio button only when the status is "Paid" */}
                  {currentData.status === "Paid" && (
                    <FormControlLabel value="Paid" control={<Radio />} label="Paid"  />
                  )}
                </RadioGroup>
              </FormControl>

            </>
          )}
        </DialogContent>
        <DialogActions>
          {/* Submit Button Disabled if Validation Fails */}
          <Button
            onClick={handleUpdate}
            variant="contained"
            color="success"
            disabled={
              currentData.status === 'Paid' ||
              currentData.isPayment === '1' ||
              !currentData.paymentType ||
              !currentData.depositerName ||
              !currentData.bankName ||
              !currentData.ChequeNo ||
              !currentData.paidAmount ||
              !currentData.paymentDate ||
              !currentData.isPaidAmountValid ||
              !currentData.isPaymentDateValid
            }
          >
            Submit
          </Button>
          <Button onClick={() => setUpdateModalPaymentOpen(false)} variant="outlined" color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    

   {/* Info Modal */}
<Dialog
  open={openInfoDialog}
  onClose={handleCloseInfoDialog}
  aria-labelledby="info-dialog-title"
  aria-describedby="info-dialog-description"
  maxWidth="md"
  fullWidth={true}
  sx={{
    '& .MuiPaper-root': {
      border: '1px solid #ccc',
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
      borderRadius: 4,
    },
  }}
>
  <DialogTitle
    id="info-dialog-title"
    sx={{
      textAlign: 'center',
      fontWeight: 'bold',
      textDecoration: 'underline',
      backgroundColor: '#f5f5f5',
      padding: 2,
      borderBottom: '2px solid #ccc',
    }}
  >
    {selectedPrehajj ? `Details for ${selectedPrehajj.umrahName}` : "Details"}
  </DialogTitle>

  {/* Profile Image Below Title */}
  {selectedPrehajj && (
    <Box
      sx={{
        textAlign: 'center',
        marginY: 2,
        padding: 2,
        border: '1px solid #ddd',
        borderRadius: 4,
        backgroundColor: '#fafafa',
      }}
    >
      <Avatar
        alt={selectedPrehajj.umrahName}
        src={selectedPrehajj.profileImage || 'https://via.placeholder.com/100'}
        sx={{
          width: 100,
          height: 100,
          margin: '0 auto',
          backgroundColor: !selectedPrehajj.profileImage ? 'gray' : '',
          border: '2px solid #007bff',
        }}
      >
        {!selectedPrehajj.profileImage && <PersonIcon />}
      </Avatar>
      <Typography variant="body1" sx={{ fontWeight: 'bold', marginTop: 1 }}>
        {selectedPrehajj.umrahName}
      </Typography>
    </Box>
  )}

  <DialogContent>
    {selectedPrehajj && (
      <Box sx={{ padding: 2 }}>
        {/* Responsive Grid Layout */}
        <Grid container spacing={2}>
          {/* Left Column */}
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              <strong>Full Name:</strong> {selectedPrehajj.umrahName}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              <strong>Agent Name:</strong> {selectedPrehajj.Agent_id ? selectedPrehajj.Agent_id.AgentName : 'N/A'}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              <strong>Father's Name:</strong> {selectedPrehajj.fatherName}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              <strong>Mother's Name:</strong> {selectedPrehajj.motherName}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              <strong>Year:</strong> {selectedPrehajj.year}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              <strong>NID No.:</strong> {selectedPrehajj.nidNo}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              <strong>Charge Amount:</strong> {selectedPrehajj.billAmount}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              <strong>Remarks:</strong> {selectedPrehajj.remark}
            </Typography>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              <strong>Passport No.:</strong> {selectedPrehajj.passportNumber}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              <strong>Phone No.:</strong> {selectedPrehajj.mobileNumber}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              <strong>Pre-Registration Date:</strong> {new Date(selectedPrehajj.registrationDate).toLocaleDateString()}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              <strong>Date of Birth:</strong> {new Date(selectedPrehajj.dateOfBirth).toLocaleDateString()}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              <strong>Serial No.:</strong> {selectedPrehajj.serialNo}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              <strong>Tracking No.:</strong> {selectedPrehajj.trackingNo}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              <strong>Reference Person:</strong> {selectedPrehajj.referencePerson}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              <strong>Payment Status:</strong> {selectedPrehajj.status}
            </Typography>
          </Grid>
        </Grid>

        {/* Address Row */}
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ marginTop: 2 }}>
            <strong>Address:</strong> {selectedPrehajj.address}
          </Typography>
        </Grid>
      </Box>
    )}
  </DialogContent>

  <DialogActions sx={{ justifyContent: 'center', padding: 2 }}>
    <Button
      onClick={handleCloseInfoDialog}
      variant="contained"
      color="secondary"
      sx={{
        borderRadius: 4,
        paddingX: 4,
      }}
    >
      Close
    </Button>
  </DialogActions>
</Dialog>


    </Box>
  );
};

export default Team;
