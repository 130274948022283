import { Box, Button, MenuItem, Typography, useTheme,IconButton } from "@mui/material";
import { tokens } from "../../../theme";
import Header from "../../../components/admincomponents/Header";
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import { useState, useEffect } from "react";
import { feachUserList, deleteUser, updateUser } from "../../../apicalls/users";
import { message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { fetchSubscriptions } from "../../../redux/subscriptionSlice"; 
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";

const Team = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const [userData, setUser] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const { subscriptions } = useSelector((state) => state.subscriptions || []);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    dispatch(fetchSubscriptions());
    fetchUserList();
  }, [dispatch]);

  const fetchUserList = async () => {
    setLoading(true);
    try {
      const response = await feachUserList();
      if (response.success) {
        setUser(response.data);
      } else {
        message.error(response.message);
      }
    } catch (error) {
      message.error("Failed to fetch user data");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleViewDetails = (user) => {
    setSelectedUser(user);
    setShowViewModal(true);
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setShowEditModal(true);
  };

  const handleDeleteUser = async (userId) => {
    try {
      const response = await deleteUser(userId);
      if (response.success) {
        message.success("User deleted successfully");
        fetchUserList(); 
      } else {
        message.error(response.message);
      }
    } catch (error) {
      message.error("Failed to delete user");
      console.error(error);
    }
  };



  const closeViewModal = () => {
    setShowViewModal(false);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();
    const updatedUser = {
        ...selectedUser,
        name: e.target.userName.value,
        email: e.target.userEmail.value,
        subscriptionPackage: e.target.userSubscription.value,
        isActive: selectedUser.isActive, 
    };
    try {
        await updateUser(updatedUser._id, updatedUser);
        message.success("User updated successfully");
        fetchUserList(); 
        closeEditModal();
    } catch (error) {
        message.error(error.message); 
    }
};

const filteredUser = userData.filter((user) =>
[user.name, user.email]
  .join(" ")
  .toLowerCase()
  .includes(searchQuery.toLowerCase())
);

const handleSearchChange = (e) => {
  setSearchQuery(e.target.value);
};
  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="USERS" subtitle="User List" />
        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <Link to="/createuser" className="text-decoration-none text-white">
              + Create Users
            </Link>
          </Button>
        </Box>
      </Box>
               {/* Search Box */}
               <Box
                display="flex"
                justifyContent="flex-end"  
                sx={{
                    padding: "5px 10px",  
                    width: "auto", 
                }}
            >
                <Box
                    display="flex"  
                    backgroundColor={colors.primary[400]} 
                    borderRadius="4px"
                    sx={{
                        width: 250, 
                        padding: "5px 10px",
                    }}
                >
                    <InputBase
                        sx={{
                            flex: 1,  
                            borderRadius: "4px",
                            padding: "5px 10px", 
                        }}
                        placeholder="Search User"
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    <IconButton type="button" sx={{ p: 1 }}>
                        <SearchIcon />
                    </IconButton>
                </Box>
            </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": { border: "none" },
          "& .MuiDataGrid-cell": { borderBottom: "none" },
          "& .name-column--cell": { color: colors.greenAccent[300] },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >

 

        <div className="table-responsive">
          <table className="table accordion table-dark">
            <thead>
              <tr>
                <th scope="col">S. No.</th>
                <th scope="col">Vendor Name</th>
                <th scope="col">Email</th>
                <th scope="col">Subscription Package</th>
                <th scope="col">Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredUser.length>0 ? filteredUser.map((user, index) => (
                <tr key={user.id || index}>
                  <th scope="row">{index + 1}</th>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.subscription_id?.name || 'NA'}</td>
                  <td>
                  <Button 
                    variant="outlined" 
                    color={user.isActive=='true' ? "success" : "error"}
                  >
                    {user.isActive=='true' ? "Active" : "Inactive"}
                  </Button>


                  </td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={() => handleViewDetails(user)}
                    >
                      <RemoveRedEyeIcon />
                    </button>
                    &nbsp;
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={() => handleEditUser(user)}
                    >
                      <EditIcon />
                    </button>
                    &nbsp;
                    <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => {
                      if (window.confirm("Are you sure you want to delete this user?")) {
                        handleDeleteUser(user._id);
                      }
                    }}
                  >
                    <DeleteIcon />
                  </button>

                  </td>
                </tr>
             )) : (
              <tr>
                  <td colSpan="6" className="text-center">No  data available</td>
              </tr>
          )}
            </tbody>
          </table>
        </div>

        {/* View Details Modal */}
        {showViewModal && (
          <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">User Details</h5>
                  <button type="button" className="btn-close" onClick={closeViewModal} aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  {selectedUser && (
                    <>
                      <p>Company Name: {selectedUser.name}</p>
                      <p>Email: {selectedUser.email}</p>
                      <p>Subscription Package: {selectedUser.subscription_id?.name || 'NA'}</p>
                    </>
                  )}
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={closeViewModal}>Close</button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Edit User Modal */}
       {/* Edit User Modal */}
      {showEditModal && (
        <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="editModalLabel">Edit User</h5>
                <button type="button" className="btn-close" onClick={closeEditModal} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                {selectedUser && (
                  <form onSubmit={handleSubmitEdit}>
                    <div className="mb-3">
                      <label htmlFor="userName" className="form-label">Company Name</label>
                      <input type="text" name="userName" className="form-control" defaultValue={selectedUser.name} />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="userEmail" className="form-label">Email</label>
                      <input type="email" name="userEmail" className="form-control" defaultValue={selectedUser.email} />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="userSubscription" className="form-label">Subscription Package</label>
                      <select name="userSubscription" className="form-control" defaultValue={selectedUser.subscriptionPackage}>
                        {Array.isArray(subscriptions) && subscriptions.length > 0 ? (
                          subscriptions.map(subscription => (
                            <option key={subscription._id} value={subscription._id}>
                              {subscription.name}
                            </option>
                          ))
                        ) : (
                          <option disabled>No subscription available</option>
                        )}
                      </select>
                    </div>
                    <div className="mb-3">
                      <label>Status:</label><br />
                      <label>
                        <input
                          type="radio"
                          name="isActive"
                          value="Active"
                          checked={selectedUser.isActive}
                          onChange={() => setSelectedUser({ ...selectedUser, isActive: true })}
                        />
                        Active
                      </label>
                      <label style={{ marginLeft: '10px' }}>
                        <input
                          type="radio"
                          name="isActive"
                          value="Inactive"
                          checked={!selectedUser.isActive}
                          onChange={() => setSelectedUser({ ...selectedUser, isActive: false })}
                        />
                        Inactive
                      </label>
                    </div>
                    <button type="submit" className="btn btn-primary">Save changes</button>
                  </form>
                )}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={closeEditModal}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}

      </Box>
    </Box>
  );
};

export default Team;
