import {
    Box,
    Button,
    Typography,
    Modal,
    TextField,
    RadioGroup,
    FormControlLabel,
    Radio,
    Dialog,
    DialogActions,
    DialogContent,
    useTheme,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    IconButton
  } from "@mui/material";
  import { tokens } from "../../theme";
  import Header from "../../components/Header";
  import EditIcon from "@mui/icons-material/Edit";
  import DeleteIcon from "@mui/icons-material/Delete";
  import { Link } from "react-router-dom";
  import {
    getairlinesticketDetailsList,
    deleteairlinesticketDetails,
    updateairlinesticketDetails,
  } from "../../apicalls/airlinesticketDetails";
  import { useState, useEffect } from "react";
  import { message } from "antd";
  import { useSelector } from "react-redux";
  import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
  
  const Team = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [dataCountries, setAirlinseticketData] = useState([]);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [currentAirlinseticket, setCurrentAirlinseticket] = useState(null);
    const { user } = useSelector((state) => state.users);
    const vendorId = user ? user._id : "";
    const [searchQuery, setSearchQuery] = useState("");
    const fetchData = async () => {
      try {
        const response = await getairlinesticketDetailsList(vendorId);
        setAirlinseticketData(response.data || []);
      } catch (error) {
        message.error("Failed to load Airlines ticket data.");
      }
    };
  
    useEffect(() => {
      fetchData();
    }, [vendorId]);
  
    const handleDeleteAirlinseticket = async (id) => {
      try {
        await deleteairlinesticketDetails(id);
        message.success("Airlines ticket deleted successfully!");
        fetchData();
      } catch (error) {
        message.error("Failed to delete Airlines ticket.");
      }
    };
  
    const handleUpdateAirlinseticket = async () => {
      try {
        await updateairlinesticketDetails(
          currentAirlinseticket._id,
          currentAirlinseticket
        );
        message.success("Airlines ticket updated successfully!");
        fetchData();
        setOpenEditModal(false);
      } catch (error) {
        message.error("Failed to update Airlines ticket.");
      }
    };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setCurrentAirlinseticket((prev) => ({
        ...prev,
        [name]: name === "isActive" ? value === "active" : value,
      }));
    };
    const filtereTickets = dataCountries.filter(tickets => {
      return (
          tickets.airlinesName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          tickets.ticketsSeries.toString().includes(searchQuery) ||  
          tickets.airlinesCode.toString().includes(searchQuery) ||
         // passport.address.toString().includes(searchQuery) ||  
          tickets.ticketClass.toLowerCase().includes(searchQuery.toLowerCase()) 
        // tickets.passportType.toLowerCase().includes(searchQuery.toLowerCase()) ||
         // passport.passportFee.toString().includes(searchQuery) ||    
        // tickets.unitPrice.includes(searchQuery)
      );
   });

  const handleSearchChange = (e) => {
      setSearchQuery(e.target.value);
    };
  
    return (
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="TICKETING AIRLINES" subtitle="Ticketing Airlines List" />
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <Link to="/createticketairlines" className="text-decoration-none text-white">
              + Add New Ticketing Airlines List
            </Link>
          </Button>
        </Box>{/* Search Box */}
        <Box
                display="flex"
                justifyContent="flex-end"  
                sx={{
                    padding: "5px 10px",  
                    width: "auto", 
                }}
            >
                <Box
                    display="flex"  
                    backgroundColor={colors.primary[400]} 
                    borderRadius="4px"
                    sx={{
                        width: 250, 
                        padding: "5px 10px",
                    }}
                >
                    <InputBase
                        sx={{
                            flex: 1,  
                            borderRadius: "4px",
                            padding: "5px 10px", 
                        }}
                        placeholder="Search Ticketing Airlines"
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    <IconButton type="button" sx={{ p: 1 }}>
                        <SearchIcon />
                    </IconButton>
                </Box>
            </Box>
  
        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
          }}
        >
          <div className="table-responsive">
            <table className="table accordion table-dark">
              <thead>
                <tr>
                  <th scope="col">Airlines Name</th>
                  <th scope="col">Airlines Code</th>
                  <th scope="col">Ticket Series</th>
                  <th scope="col">Class</th>
                  <th scope="col">Qty</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {filtereTickets.length > 0 ?   filtereTickets.map((Airlinseticket) => (
                  <tr key={Airlinseticket._id}>
                    <td>{Airlinseticket.airlinesName}</td>
                    <td>{Airlinseticket.airlinesCode}</td>
                    <td>{Airlinseticket.ticketsSeries}</td>
                    <td>{Airlinseticket.ticketClass}</td>
                    <td>{Airlinseticket.ticketQty}</td>
                    <td>{Airlinseticket.isActive ? "Active" : "Inactive"}</td>
                    <td>
                      <EditIcon
                        onClick={() => {
                          setCurrentAirlinseticket(Airlinseticket);
                          setOpenEditModal(true);
                        }}
                      />
                      <DeleteIcon
                        onClick={() => {
                          setCurrentAirlinseticket(Airlinseticket);
                          setOpenDeleteModal(true);
                        }}
                        style={{
                          cursor: 'pointer',
                          color: 'red',       
                        }}
                      />
                    </td>
                  </tr>
                 )) : (
                  <tr>
                      <td colSpan="8" className="text-center">No Ticket data available</td>
                  </tr>
              )}
              </tbody>
            </table>
          </div>
        </Box>
  
        {/* Edit Modal */}
        <Modal open={openEditModal} onClose={() => setOpenEditModal(false)}>
        <Box sx={modalStyle}>
            <DialogContent>
            <Typography variant="h6">Edit Airlines Ticket</Typography>

            <TextField
                label="Airlines Name"
                name="airlinesName"
                value={currentAirlinseticket?.airlinesName || ""}
                onChange={handleChange}
                fullWidth
                margin="normal"
            />

            <TextField
                label="Airlines Code"
                name="airlinesCode"
                value={currentAirlinseticket?.airlinesCode || ""}
                onChange={handleChange}
                fullWidth
                margin="normal"
            />

            <TextField
                label="Ticket Series"
                name="ticketsSeries"
                value={currentAirlinseticket?.ticketsSeries || ""}
                onChange={handleChange}
                fullWidth
                margin="normal"
            />

            <TextField
                label="Ticket Qty"
                name="ticketQty"
                value={currentAirlinseticket?.ticketQty || ""}
                onChange={handleChange}
                fullWidth
                margin="normal"
            />

            {/* Ticket Class Selection */}
            <Box
                display="grid"
                gap="30px"
                mt="20px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                "& > div": { gridColumn: "span 4" },
                }}
            >
            <FormControl
                fullWidth
                variant="filled"
                sx={{ gridColumn: "span 2", mb: 2 }}
                >
                <InputLabel id="ticket-class-select-label">Select Ticket Class</InputLabel>
                <Select
                    labelId="ticket-class-select-label"
                    value={currentAirlinseticket?.ticketClass || ""}
                    onChange={handleChange}
                    name="ticketClass"
                >
                    <MenuItem value="Economy">Economy</MenuItem>
                    <MenuItem value="Business">Business</MenuItem>
                    <MenuItem value="First">First Class</MenuItem>
                </Select>
                </FormControl>
            </Box>

            <Typography variant="subtitle1">Status</Typography>
            <RadioGroup
                row
                name="isActive"
                value={currentAirlinseticket?.isActive ? "Active" : "Inactive"}
                onChange={handleChange}
            >
                <FormControlLabel value="Active" control={<Radio />} label="Active" />
                <FormControlLabel value="Inactive" control={<Radio />} label="Inactive" />
            </RadioGroup>
            </DialogContent>

            <DialogActions>
            <Button onClick={handleUpdateAirlinseticket} color="primary">
                Save Changes
            </Button>
            </DialogActions>
        </Box>
        </Modal>

  
        {/* Delete Confirmation Dialog */}
        <Dialog open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to delete this Airlines ticket?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleDeleteAirlinseticket(currentAirlinseticket._id)} color="primary">
              Yes
            </Button>
            <Button onClick={() => setOpenDeleteModal(false)} color="secondary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  };
  
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  
  export default Team;
  