import { Box, Button, Typography, useTheme, Modal, TextField,IconButton, RadioGroup, FormControlLabel, Radio, Dialog, DialogActions, DialogContent } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import { getCountryDetailsList, deleteCountryDetails, updateCountryDetails } from "../../apicalls/countrydetails";
import { useState, useEffect } from "react";
import { message } from "antd";
import { useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";

const Team = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [dataCountries, setCountryData] = useState([]);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [currentCountry, setCurrentCountry] = useState(null);
    const { user } = useSelector((state) => state.users);
    const vendorId = user ? user._id : "";
    const [searchQuery, setSearchQuery] = useState("");

    const fetchData = async () => {
        try {
            const response = await getCountryDetailsList(vendorId);
            setCountryData(response.data || []);
        } catch (error) {
            console.error("Error fetching country data:", error);
            message.error("Failed to load country data.");
        }
    };

    useEffect(() => {
        fetchData();
    }, [vendorId]);

    const handleDeleteCountry = async (id) => {
        try {
            await deleteCountryDetails(id);
            message.success("Country deleted successfully!");
            fetchData(); 
        } catch (error) {
            message.error("Failed to delete country.");
        }
    };

    const handleUpdateCountry = async () => {
        try {
            await updateCountryDetails(currentCountry._id, currentCountry);
            message.success("Country updated successfully!");
            fetchData();
            setOpenEditModal(false);
        } catch (error) {
            message.error("Failed to update country.");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCurrentCountry((prev) => ({
            ...prev,
            [name]: name === "isActive" ? value === "active" : value,
        }));
    };
    const filtereCountries = dataCountries.filter(bank => {
        return (
            bank.CountryName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            bank.Description.toLowerCase().toString().includes(searchQuery) ||
            bank.CountryCode.toLowerCase().toString().includes(searchQuery) 
        );
     });

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
      };

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="COUNTRY" subtitle="Country List" />
                <Button
                    sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                    }}
                >
                    <Link to="/addcountry" className="text-decoration-none text-white">
                        + Add New Country
                    </Link>
                </Button>
            </Box>
 {/* Search Box */}
            <Box
                display="flex"
                justifyContent="flex-end"  
                sx={{
                    padding: "5px 10px",  
                    width: "auto", 
                }}
            >
                <Box
                    display="flex"  
                    backgroundColor={colors.primary[400]} 
                    borderRadius="4px"
                    sx={{
                        width: 250, 
                        padding: "5px 10px",
                    }}
                >
                    <InputBase
                        sx={{
                            flex: 1,  
                            borderRadius: "4px",
                            padding: "5px 10px", 
                        }}
                        placeholder="Search Country"
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    <IconButton type="button" sx={{ p: 1 }}>
                        <SearchIcon />
                    </IconButton>
                </Box>
            </Box>
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": { border: "none" },
                    "& .MuiDataGrid-cell": { borderBottom: "none" },
                    "& .name-column--cell": { color: colors.greenAccent[300] },
                    "& .MuiDataGrid-columnHeaders": { backgroundColor: colors.blueAccent[700], borderBottom: "none" },
                    "& .MuiDataGrid-virtualScroller": { backgroundColor: colors.primary[400] },
                    "& .MuiDataGrid-footerContainer": { borderTop: "none", backgroundColor: colors.blueAccent[700] },
                    "& .MuiCheckbox-root": { color: `${colors.greenAccent[200]} !important` },
                }}
            >
                <div className="table-responsive">
                    <table className="table accordion table-dark">
                        <thead>
                            <tr>
                                <th scope="col">Country Name</th>
                                <th scope="col">Country Code</th>
                                <th scope="col">Country Description</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filtereCountries.length>0 ? filtereCountries.map((country) => (
                                <tr key={country._id}>
                                    <td>{country.CountryName}</td>
                                    <td>{country.CountryCode}</td>
                                    <td>{country.Description}</td>
                                    <td>
                                        <EditIcon onClick={() => {
                                            setCurrentCountry(country);
                                            setOpenEditModal(true);
                                        }} />
                                        <DeleteIcon onClick={() => {
                                            setCurrentCountry(country);
                                            setOpenDeleteModal(true);
                                        }}  style={{
                                            cursor: 'pointer',
                                            color: 'red',       
                                          }} />
                                    </td>
                                </tr>
                             )) : (
                            <tr>
                                <td colSpan="9" className="text-center">No Country data available</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </Box>

            {/* Edit Modal */}
            <Modal open={openEditModal} onClose={() => setOpenEditModal(false)}>
                <Box sx={{ ...modalStyle }}>
                    <DialogContent>
                        <Typography variant="h6">Edit Country</Typography>

                        <TextField
                            label="Country Name"
                            name="CountryName"
                            value={currentCountry?.CountryName || ""}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />

                        <TextField
                            label="Country Code"
                            name="CountryCode"
                            value={currentCountry?.CountryCode || ""}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />

                        <TextField
                            label="Country Description"
                            name="Description"
                            value={currentCountry?.Description || ""}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />

                        {/* Status Radio Buttons */}
                        <Typography variant="subtitle1">Status</Typography>
                        <RadioGroup
                            row
                            name="isActive"
                            value={currentCountry?.isActive ? "active" : "inactive"}
                            onChange={handleChange}
                        >
                            <FormControlLabel value="active" control={<Radio />} label="Active" />
                            <FormControlLabel value="inactive" control={<Radio />} label="Inactive" />
                        </RadioGroup>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleUpdateCountry} color="primary">Save Changes</Button>
                    </DialogActions>
                </Box>
            </Modal>

            {/* Delete Confirmation Dialog */}
            <Dialog open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
                <DialogContent>
                    <Typography variant="h6">Are you sure you want to delete this country?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDeleteCountry(currentCountry._id)} color="primary">Yes</Button>
                    <Button onClick={() => setOpenDeleteModal(false)} color="secondary">No</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default Team;
