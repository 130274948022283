import { Box, Button, Radio, TextField, IconButton, InputAdornment } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header"; 
import { saveAgent } from "../../apicalls/agent"; 
import { useState } from "react";
import { message } from "antd";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import './index.css';
import { Visibility, VisibilityOff } from '@mui/icons-material'; 
const Form = () => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const { user } = useSelector((state) => state.users);
    const vendorId = user ? user._id : ""; 
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleFormSubmit = async (values, { resetForm }) => {
        try {
            const formData = new FormData();
            Object.keys(values).forEach((key) => {
                if (key !== "agentDocumentFile") {
                    formData.append(key, values[key]);
                }
            });
            if (values.agentDocumentFile) {
                formData.append("agentDocumentFile", values.agentDocumentFile);
            }
            const response = await saveAgent(formData); 
            if (response.success) {
                message.success(response.message);
                resetForm(); 
            } else {
                message.error(response.message);
            }
        } catch (error) {
            message.error("An error occurred. Please try again.");
        }
        
    };

    const initialValues = {
        AgentName: "",
        CompanyName: "",
        BankName: "",
        BranchName: "",
        AccountNumber: "",
        PhoneNumber: "",
        Email: "",
        NIDNumber: "",
        Address: "",
        fileTitle: "File Name",
        agentDocumentFile: null, 
        isActive: "Active",
        vendor_id: vendorId,
        password: "",
        confirmPassword: "",
    };

    const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

    const checkoutSchema = yup.object().shape({
        AgentName: yup.string().required("required"),
        // BankName: yup.string().required("required"),
         CompanyName: yup.string().required("required"),
        // BranchName: yup.string().required("required"),
        AccountNumber: yup.number().typeError("Account number must be a number"),
        Email: yup.string().email("invalid email").required("required"),
        PhoneNumber: yup.string().matches(phoneRegExp, "Phone number is not valid").required("required"),
        // NIDNumber: yup.string().required("required"),
        // Address: yup.string().required("required"),
        // fileTitle: yup.string().required("required"),
        // agentDocumentFile: yup.mixed().required("required"),
        // isActive: yup.string().oneOf(['Active', 'Inactive']).required("required"),
        password: yup.string().required("Password is required"),
        confirmPassword: yup.string()
            .oneOf([yup.ref('password'), null], 'Passwords must match')
            .required("Confirm Password is required"),
    });

    return (
        <Box m="20px">
            <Header title="CREATE AGENT" subtitle="Create a New Agent" />
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm }) => (
                    <form onSubmit={handleSubmit}>
                        <h5>Agent Details</h5>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            {['AgentName', 'CompanyName', 'BankName', 'BranchName', 'AccountNumber', 'PhoneNumber', 'Email', 'NIDNumber', 'Address'].map((field, index) => (
                                <TextField
                                    key={index}
                                    fullWidth
                                    variant="filled"
                                    type={field.includes('Number') ? "text" : field === 'Email' ? "email" : "text"}
                                    label={field.replace(/([A-Z])/g, ' $1').trim()} 
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values[field]}
                                    name={field}
                                    error={!!touched[field] && !!errors[field]}
                                    helperText={touched[field] && errors[field]}
                                    sx={{ gridColumn: "span 2" }}
                                />
                            ))}
                        </Box>

                        <h5 className="mt-3">Password</h5>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(2, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="filled"
                                label="Password"
                                type={showPassword ? "text" : "password"}
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.password && !!errors.password}
                                helperText={touched.password && errors.password}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowPassword(!showPassword)}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                label="Confirm Password"
                                type={showConfirmPassword ? "text" : "password"}
                                name="confirmPassword"
                                value={values.confirmPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.confirmPassword && !!errors.confirmPassword}
                                helperText={touched.confirmPassword && errors.confirmPassword}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>

                        <h5 className="mt-3">Agent Documents</h5>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="File Title"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.fileTitle}
                                name="fileTitle"
                                error={!!touched.fileTitle && !!errors.fileTitle}
                                helperText={touched.fileTitle && errors.fileTitle}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="file"
                                onChange={(event) => {
                                    handleChange({
                                        target: {
                                            name: 'agentDocumentFile',
                                            value: event.target.files[0],
                                        },
                                    });
                                }}
                                helperText={touched.agentDocumentFile && errors.agentDocumentFile}
                                sx={{ gridColumn: "span 2" }}
                            />
                        </Box>

                        <h5 className="mt-3">isActive</h5>
                        <Box>
                            <Radio
                                checked={values.isActive === 'Active'}
                                onChange={handleChange}
                                value="Active"
                                name="isActive"
                                slotProps={{ input: { 'aria-label': 'Active' } }}
                            /> Active
                            <Radio
                                checked={values.isActive === 'Inactive'}
                                onChange={handleChange}
                                value="Inactive"
                                name="isActive"
                                slotProps={{ input: { 'aria-label': 'Inactive' } }}
                            /> Inactive
                        </Box>

                        <Box display="flex" justifyContent="end" mt="20px">
                            <Button
                                type="button"
                                color="primary" 
                                variant="contained"
                                style={{
                                    backgroundColor: '#0b65e9', 
                                    color: '#fafafa !important', 
                                    borderColor: '#1976d2',
                                }}
                            >
                                <Link to="/agentlist" className="link-style">
                                    Cancel
                                </Link>
                            </Button>&nbsp;&nbsp;
                            <Button type="submit" color="secondary" variant="contained">
                                Create New Agent
                            </Button>&nbsp;&nbsp;
                            <Button
                                className="btn-reset"
                                type="button"
                                color="primary"
                                variant="contained"
                                onClick={() => resetForm()}
                                style={{
                                    backgroundColor: '#e2e2e2', 
                                    color: '#1976d2',
                                    borderColor: '#1976d2', 
                                }} >
                                Reset
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
};

export default Form;
