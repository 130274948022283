import {
    Box,
    Button,
    Typography,
    Modal,
    TextField,
    RadioGroup,
    FormControlLabel,
    Radio,
    Dialog,
    DialogActions,
    DialogContent,
    useTheme,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    IconButton
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import {
    getairlinesticketpurchaseDetailsList,
    deleteairlinesticketpurchaseDetails,
    updateairlinesticketpurchaseDetails,
} from "../../apicalls/airlinesticketpurchaseDetails";
import { useState, useEffect } from "react";
import { message } from "antd";
import { useSelector,useDispatch } from "react-redux"; 
import { fetchsupplier } from "../../redux/supplierSlice"; 
import { fetchairline } from "../../redux/airlinesSlice"; 
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";

const Team = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [dataCountries, setAirlinseticketData] = useState([]);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [currentAirlinseticket, setCurrentAirlinseticket] = useState(null);
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.users);
    const vendorId = user ? user._id : "";
    const [searchQuery, setSearchQuery] = useState("");
    const fetchData = async () => {
        try {
            const response = await getairlinesticketpurchaseDetailsList(vendorId);
            setAirlinseticketData(response.data || []);
        } catch (error) {
            message.error("Failed to load Airlines ticket data.");
        }
    };
    useEffect(() => {
        if (vendorId) {
          dispatch(fetchsupplier(vendorId));
          dispatch(fetchairline(vendorId));
          fetchData();
        }
       
      }, [dispatch, vendorId]);

    const handleDeleteAirlinseticket = async (id) => {
        try {
            await deleteairlinesticketpurchaseDetails(id);
            message.success("Airlines ticket deleted successfully!");
            fetchData();
        } catch (error) {
            message.error("Failed to delete Airlines ticket.");
        }
    };

    const handleUpdateAirlinseticket = async () => {
        try {
            await updateairlinesticketpurchaseDetails(
                currentAirlinseticket._id,
                currentAirlinseticket
            );
            message.success("Airlines ticket updated successfully!");
            fetchData();
            setOpenEditModal(false);
        } catch (error) {
            message.error("Failed to update Airlines ticket.");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCurrentAirlinseticket((prev) => ({
            ...prev,
            [name]: name === "isActive" ? value === "Active" : value,
        }));
    };
    const filtereTickets = dataCountries.filter(tickets => {
        return (
            tickets.invoice.toLowerCase().includes(searchQuery.toLowerCase()) ||
            tickets.airlinseId.toString().includes(searchQuery) ||  
            tickets.airlinesType.toString().includes(searchQuery) ||
           // passport.address.toString().includes(searchQuery) ||  
            tickets.supplierId.toLowerCase().includes(searchQuery.toLowerCase()) 
          // tickets.passportType.toLowerCase().includes(searchQuery.toLowerCase()) ||
           // passport.passportFee.toString().includes(searchQuery) ||    
          // tickets.unitPrice.includes(searchQuery)
        );
     });

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
      };
    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="TICKETING AIRLINES" subtitle="Ticketing Airlines List" />
                <Button
                    sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                    }}
                >
                    <Link to="/createticketpurchase" className="text-decoration-none text-white">
                        + Add New Ticket Purchase
                    </Link>
                </Button>
            </Box>
        {/* Search Box */}
        <Box
                display="flex"
                justifyContent="flex-end"  
                sx={{
                    padding: "5px 10px",  
                    width: "auto", 
                }}
            >
                <Box
                    display="flex"  
                    backgroundColor={colors.primary[400]} 
                    borderRadius="4px"
                    sx={{
                        width: 250, 
                        padding: "5px 10px",
                    }}
                >
                    <InputBase
                        sx={{
                            flex: 1,  
                            borderRadius: "4px",
                            padding: "5px 10px", 
                        }}
                        placeholder="Search Ticket"
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    <IconButton type="button" sx={{ p: 1 }}>
                        <SearchIcon />
                    </IconButton>
                </Box>
            </Box>
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                }}
            >
                <div className="table-responsive">
                    <table className="table accordion table-dark">
                        <thead>
                            <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Invoice</th>
                                <th scope="col">Airlines Name</th>
                                <th scope="col">Ticket Type</th>
                                <th scope="col">Supplier</th>
                                <th scope="col">Unit Price</th>
                                <th scope="col">Qty</th>
                                <th scope="col">Payable Amount</th>
                                <th scope="col">Paid Amount</th>
                                <th scope="col">Due Amount</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filtereTickets.length > 0 ? filtereTickets.map((Airlinseticket) => (
                                <tr key={Airlinseticket._id}>
                                    <td>{new Date(Airlinseticket.date || Date.now()).toLocaleDateString()}</td>
                                    <td>{Airlinseticket.invoice}</td>
                                    <td>{Airlinseticket.airlinseId?.airlinesName || 'NA'}</td>
                                    <td>{Airlinseticket.airlinesType} Class</td>
                                    <td>{Airlinseticket.supplierId?.SupplierName || 'NA'}</td>
                                    <td>{Airlinseticket.unitPrice}</td>
                                    <td>{Airlinseticket.ticketQty}</td>
                                    <td>{Airlinseticket.payableAmount}</td>
                                    <td>{Airlinseticket.paidAmount}</td>
                                    <td>{Airlinseticket.dueAmount}</td>
                                    <td>{Airlinseticket.paymentStatus}</td>
                                    <td>
                                        <EditIcon
                                            onClick={() => {
                                                setCurrentAirlinseticket(Airlinseticket);
                                                setOpenEditModal(true);
                                            }}
                                        />
                                        <DeleteIcon
                                            onClick={() => {
                                                setCurrentAirlinseticket(Airlinseticket);
                                                setOpenDeleteModal(true);
                                            }}
                                            style={{
                                                cursor: 'pointer',
                                                color: 'red',       
                                              }}
                                        />
                                    </td>
                                </tr>
                           )) : (
                            <tr>
                                <td colSpan="12" className="text-center">No Ticket data available</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </Box>

            {/* Edit Modal */}
            <Modal open={openEditModal} onClose={() => setOpenEditModal(false)}>
                <Box sx={modalStyle}>
                    <DialogContent>
                        <Typography variant="h6">Edit Airlines Purchase</Typography>
                        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                            <TextField
                                label="Date"
                                name="date"
                                type="date"
                                value={currentAirlinseticket?.date?.substring(0, 10) || ""}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                            />

                            <TextField
                                label="Invoice"
                                name="invoice"
                                value={currentAirlinseticket?.invoice || ""}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                            />

                            <TextField
                                label="Airlines Name"
                                name="airlinesName"
                                value={currentAirlinseticket?.airlinesName || ""}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                            />

                        
                            <TextField
                                label="Supplier"
                                name="supplier"
                                value={currentAirlinseticket?.supplier || ""}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                            />

                            <TextField
                                label="Unit Price"
                                name="unitPrice"
                                type="number"
                                value={currentAirlinseticket?.unitPrice || ""}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                            />

                            <TextField
                                label="Ticket Qty"
                                name="ticketQty"
                                value={currentAirlinseticket?.ticketQty || ""}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                            />

                            <TextField
                                label="Payable Amount"
                                name="payableAmount"
                                type="number"
                                value={currentAirlinseticket?.payableAmount || ""}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                            />

                            <TextField
                                label="Paid Amount"
                                name="paidAmount"
                                type="number"
                                value={currentAirlinseticket?.paidAmount || ""}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                            />

                            <TextField
                                label="Due Amount"
                                name="dueAmount"
                                type="number"
                                value={currentAirlinseticket?.dueAmount || ""}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                            />

                            {/* Ticket Class Selection */}
                            <Box display="grid" gap="30px" mt="20px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" sx={{ "& > div": { gridColumn: "span 4" } }}>
                                <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 2", mb: 2 }}>
                                    <InputLabel id="ticket-class-select-label">Select Ticket Class</InputLabel>
                                    <Select
                                        labelId="ticket-class-select-label"
                                        value={currentAirlinseticket?.airlinesType || ""}
                                        onChange={handleChange}
                                        name="airlinesType"
                                    >
                                        <MenuItem value="Economy">Economy</MenuItem>
                                        <MenuItem value="Business">Business</MenuItem>
                                        <MenuItem value="First">First Class</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>

                            <Typography variant="subtitle1">Status</Typography>
                            <RadioGroup
                                row
                                name="isActive"
                                value={currentAirlinseticket?.isActive ? "Active" : "Inactive"}
                                onChange={handleChange}
                            >
                                <FormControlLabel value="Active" control={<Radio />} label="Active" />
                                <FormControlLabel value="Inactive" control={<Radio />} label="Inactive" />
                            </RadioGroup>
                        </div>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleUpdateAirlinseticket} color="primary">Save</Button>
                        <Button onClick={() => setOpenEditModal(false)} color="secondary">Cancel</Button>
                    </DialogActions>
                </Box>
            </Modal>


            {/* Delete Confirmation Modal */}
            <Dialog open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
                <DialogContent>
                    <Typography>Are you sure you want to delete this Airlines Ticket?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDeleteAirlinseticket(currentAirlinseticket._id)} color="primary">Yes</Button>
                    <Button onClick={() => setOpenDeleteModal(false)} color="secondary">No</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default Team;
