import { Box, Button, TextField, Select, MenuItem, InputLabel, FormControl, FormHelperText } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { savePassportDetails } from "../../apicalls/passportDetails";
import { useSelector,useDispatch } from "react-redux";
import { message } from "antd";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { fetchCountries } from "../../redux/countrySlice"; 
import { fetchAgents } from "../../redux/agentsSlice"; 
import { useEffect } from "react";
import { Link } from "react-router-dom";
const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { user } = useSelector((state) => state.users); 
  const vendorId = user ? user._id : ""; 
  const { agents } = useSelector((state) => state.agents || []); 
  const { countries } = useSelector((state) => state.countries || []); 
  const dispatch = useDispatch();
  useEffect(() => {
    if (vendorId) {
      dispatch(fetchAgents(vendorId));
      dispatch(fetchCountries(vendorId));
    }
   
  }, [dispatch, vendorId]);

  const handleFormSubmit = async (values, { resetForm }) => {
    try {
      const passportData = { ...values, vendor_id: vendorId };
      await savePassportDetails(passportData);
      message.success("Passport details saved successfully!");
      resetForm(); 
    } catch (error) {
      message.error("Error saving passport details. Please try again.");
      console.error("Error saving passport details:", error);
    }
  };

  const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

  const checkoutSchema = yup.object().shape({
    fullName: yup.string().required("Required"),
    reference: yup.string().required("Required"),
    fatherName: yup.string().required("Required"),
    motherName: yup.string().required("Required"),
    phoneNumber: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Required"),
    emergencyNumber: yup.string().required("Required"),
    passportNumber: yup.string().required("Required"),
    dateOfBirth: yup.date().required("Required"),
    agent_id: yup.string().required("Required"),
    passportType: yup.string().required("Required"),
    presentAddress: yup.string().required("Required"),
    permanentAddress: yup.string().required("Required"),
    feeAmount: yup.number().required("Required"),
    serviceCharge: yup.number().required("Required"),
    documentTitle: yup.string().required("Required"),
    country_id: yup.string().required("Country is required"),
    paidAmount: yup.number().required("Paid amount is required").min(0),
    dueAmount: yup.number().required("Due amount is required").min(0),
  });

  const initialValues = {
    fullName: "",
    reference: "",
    fatherName: "",
    motherName: "",
    phoneNumber: "",
    emergencyNumber: "",
    passportNumber: "",
    dateOfBirth: "",
    agent_id: "",
    passportType: "",
    presentAddress: "",
    permanentAddress: "",
    feeAmount: "",
    serviceCharge: "",
    documentTitle: "",
    remarks: "",
    paidAmount:0,
    dueAmount:0,
    documentFile: null,
  };

  return (
    <Box m="20px">
      <Header title="PASSPORT PROCESSING" subtitle="Create Passport" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <FormControl fullWidth variant="filled" error={!!touched.agent_id && !!errors.agent_id} sx={{ gridColumn: "span 2" }}>
                <InputLabel id="agent-select-label">Select Agent</InputLabel>
                <Select
                  labelId="agent-select-label"
                  value={values.agent_id}
                  onChange={handleChange}
                  name="agent_id"
                  onBlur={handleBlur}
                >
                   {Array.isArray(agents) && agents.length > 0 ? (
                          agents.map(agent => (
                              <MenuItem key={agent._id} value={agent._id}>
                                  {agent.AgentName}
                              </MenuItem>
                          ))
                      ) : (
                          <MenuItem disabled>No agents available</MenuItem>
                      )}
                </Select>
                {touched.agent_id && errors.agent_id && (
                  <FormHelperText>{errors.agent_id}</FormHelperText>
                )}
              </FormControl>

              <TextField
                fullWidth
                variant="filled"
                label="Full Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fullName}
                name="fullName"
                error={!!touched.fullName && !!errors.fullName}
                helperText={touched.fullName && errors.fullName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Reference"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.reference}
                name="reference"
                error={!!touched.reference && !!errors.reference}
                helperText={touched.reference && errors.reference}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Father's Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fatherName}
                name="fatherName"
                error={!!touched.fatherName && !!errors.fatherName}
                helperText={touched.fatherName && errors.fatherName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Mother's Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.motherName}
                name="motherName"
                error={!!touched.motherName && !!errors.motherName}
                helperText={touched.motherName && errors.motherName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Phone Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phoneNumber}
                name="phoneNumber"
                error={!!touched.phoneNumber && !!errors.phoneNumber}
                helperText={touched.phoneNumber && errors.phoneNumber}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Emergency Contact No"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.emergencyNumber}
                name="emergencyNumber"
                error={!!touched.emergencyNumber && !!errors.emergencyNumber}
                helperText={touched.emergencyNumber && errors.emergencyNumber}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Passport No"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.passportNumber}
                name="passportNumber"
                error={!!touched.passportNumber && !!errors.passportNumber}
                helperText={touched.passportNumber && errors.passportNumber}
                sx={{ gridColumn: "span 2" }}
              />
                <TextField
                fullWidth
                variant="filled"
                label="Date of Birth"
                type="date" 
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.dateOfBirth}
                name="dateOfBirth"
                InputLabelProps={{ shrink: true }}
                error={!!touched.dateOfBirth && !!errors.dateOfBirth}
                helperText={touched.dateOfBirth && errors.dateOfBirth}
                sx={{ gridColumn: "span 2" }}
              />
              
              <FormControl fullWidth variant="filled" error={!!touched.passportType && !!errors.passportType} sx={{ gridColumn: "span 2" }}>
                <InputLabel id="passport-type-label">Passport Type</InputLabel>
                <Select
                  labelId="passport-type-label"
                  value={values.passportType}
                  onChange={handleChange}
                  name="passportType"
                  onBlur={handleBlur}
                >
                    <MenuItem value="regular">Regular Passport</MenuItem>
                    <MenuItem value="diplomatic">Diplomatic Passport</MenuItem>
                    <MenuItem value="official">Official Passport</MenuItem>
                </Select>
                {touched.passportType && errors.passportType && (
                  <FormHelperText>{errors.passportType}</FormHelperText>
                )}
              </FormControl>
        {/* Country */}
        <FormControl fullWidth variant="filled" error={!!touched.country_id && !!errors.country_id} sx={{ gridColumn: "span 2" }}>
                <InputLabel id="country-purpose-label">Country</InputLabel>
                <Select
                  labelId="country_id-purpose-label"
                  value={values.country_id}
                  onChange={handleChange}
                  name="country_id"
                  onBlur={handleBlur}
                >
                {Array.isArray(countries) && countries.length > 0 ? (
                          countries.map(country => (
                              <MenuItem key={country._id} value={country._id}>
                                 {country.CountryName}
                              </MenuItem>
                          ))
                      ) : (
                          <MenuItem disabled>No countries available</MenuItem>
                      )}
                </Select>
                {touched.country_id && errors.country_id && (
                  <FormHelperText>{errors.country_id}</FormHelperText>
                )}
              </FormControl>

              
              <TextField
                fullWidth
                variant="filled"
                label="Present Address"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.presentAddress}
                name="presentAddress"
                error={!!touched.presentAddress && !!errors.presentAddress}
                helperText={touched.presentAddress && errors.presentAddress}
                multiline
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Permanent Address"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.permanentAddress}
                name="permanentAddress"
                error={!!touched.permanentAddress && !!errors.permanentAddress}
                helperText={touched.permanentAddress && errors.permanentAddress}
                multiline
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Fee Amount"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.feeAmount}
                name="feeAmount"
                error={!!touched.feeAmount && !!errors.feeAmount}
                helperText={touched.feeAmount && errors.feeAmount}
                sx={{ gridColumn: "span 2" }}
              />
              
              <TextField
                fullWidth
                variant="filled"
                label="Service Charge"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.serviceCharge}
                name="serviceCharge"
                error={!!touched.serviceCharge && !!errors.serviceCharge}
                helperText={touched.serviceCharge && errors.serviceCharge}
                sx={{ gridColumn: "span 2" }}
              />
              
               {/* Due Charge */}
               <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Due Amount"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.dueAmount}
                name="dueAmount"
                error={!!touched.dueAmount && !!errors.dueAmount}
                helperText={touched.dueAmount && errors.dueAmount}
                sx={{ gridColumn: "span 2" }}
              />

               {/* Paid Charge */}
               <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Paid Amount"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.paidAmount}
                name="paidAmount"
                error={!!touched.paidAmount && !!errors.paidAmount}
                helperText={touched.paidAmount && errors.paidAmount}
                sx={{ gridColumn: "span 2" }}
              />
              {/* Remarks */}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Remarks"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.remarks}
                name="remarks"
                error={!!touched.remarks && !!errors.remarks}
                helperText={touched.remarks && errors.remarks}
                sx={{ gridColumn: "span 2" }}
              />
            </Box>

            <h5 style={{ marginTop: "16px" }}>Additional Documents</h5>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                label="File Title"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.documentTitle}
                name="documentTitle"
                error={!!touched.documentTitle && !!errors.documentTitle}
                helperText={touched.documentTitle && errors.documentTitle}
                sx={{ gridColumn: "span 2" }}
              />
              <input
                type="file"
                onChange={(e) => handleChange({ target: { name: 'documentFile', value: e.target.files[0] } })}
                name="documentFile"
                style={{ gridColumn: "span 2", width: "100%", padding: "16px", borderRadius: "4px", border: "1px solid #ccc" }}
              />
            </Box>

            <Box display="flex" justifyContent="end" mt="20px">
            <Button
                type="button"
                color="primary"
                variant="contained"
                style={{
                  backgroundColor: '#0b65e9',
                  color: '#fafafa !important',
                  borderColor: '#1976d2',
                }}
              >
                <Link to="/passports" className="link-style">
                  Cancel
                </Link>
              </Button>
              &nbsp;&nbsp;
              <Button type="submit" color="secondary" variant="contained">
                Submit
              </Button>&nbsp;&nbsp;
              <Button type="button" color="primary" variant="contained" onClick={resetForm}>
                Reset
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default Form;
