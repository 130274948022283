import {
    Box,
    Button,
    Typography,
    useTheme,
    Modal,
    TextField,
    RadioGroup,
    FormControlLabel,
    Radio,
    Dialog,
    FormControl,
    DialogActions,
    DialogContent,
    InputLabel,
    Select,
    MenuItem,
    IconButton,
    InputBase,
  } from "@mui/material";
  
  import { tokens } from "../../theme";
  import Header from "../../components/Header";
  import EditIcon from "@mui/icons-material/Edit";
  import DeleteIcon from "@mui/icons-material/Delete";
  import SearchIcon from "@mui/icons-material/Search";
  import { Link } from "react-router-dom";
  import { useState, useEffect } from "react";
  import { message } from "antd";
  import { useSelector, useDispatch } from "react-redux";
  import {
    getEmbassyDetailsList,
    deleteEmbassyDetails,
    updateEmbassyDetails,
  } from "../../apicalls/empbassyDetails";
  import { fetchCountries } from "../../redux/countrySlice";
  
  const Team = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [dataCountries, setEmbassyData] = useState([]);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [currentEmbassy, setCurrentEmbassy] = useState(null);
    const { user } = useSelector((state) => state.users);
    const vendorId = user ? user._id : "";
    const dispatch = useDispatch();
    const { countries } = useSelector((state) => state.countries || []);
    const [searchQuery, setSearchQuery] = useState("");
  
    const fetchData = async () => {
      try {
        const response = await getEmbassyDetailsList(vendorId);
        setEmbassyData(response.data || []);
      } catch (error) {
        console.error("Error fetching Embassy data:", error);
        message.error("Failed to load Embassy data.");
      }
    };
  
    useEffect(() => {
      dispatch(fetchCountries(vendorId));
      fetchData();
    }, [dispatch, vendorId]);
  
    const handleDeleteEmbassy = async (id) => {
      try {
        await deleteEmbassyDetails(id);
        message.success("Embassy deleted successfully!");
        fetchData();
        setOpenDeleteModal(false);
      } catch (error) {
        message.error("Failed to delete Embassy.");
      }
    };
  
    const handleUpdateEmbassy = async () => {
      try {
        if (currentEmbassy) {
          await updateEmbassyDetails(currentEmbassy._id, currentEmbassy);
          message.success("Embassy updated successfully!");
          fetchData();
          setOpenEditModal(false);
        }
      } catch (error) {
        message.error("Failed to update Embassy.");
      }
    };
  
    const filteredEmbassy = dataCountries.filter((embassy) =>
      [
        embassy.embassyName,
        embassy.email,
        embassy.note,
        embassy.country,
        embassy.passportNumber,
        embassy.phoneNumber,
      ]
        .filter(Boolean)
        .some((field) => field.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  
    const handleSearchChange = (e) => {
      setSearchQuery(e.target.value);
    };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setCurrentEmbassy((prev) => ({
        ...prev,
        [name]: name === "isActive" ? value === "active" : value,
      }));
    };
  
    return (
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Embassy" subtitle="Embassy List" />
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <Link to="/addEmbassy" className="text-decoration-none text-white">
              + Add New Embassy
            </Link>
          </Button>
        </Box>
  
        {/* Search Box */}
        <Box display="flex" justifyContent="flex-end" sx={{ padding: "10px" }}>
          <Box
            display="flex"
            backgroundColor={colors.primary[400]}
            borderRadius="4px"
            sx={{ width: 250 }}
          >
            <InputBase
              sx={{ flex: 1, padding: "5px 10px" }}
              placeholder="Search Embassy"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <IconButton type="button" sx={{ p: 1 }}>
              <SearchIcon />
            </IconButton>
          </Box>
        </Box>
  
        {/* Embassy Table */}
        <Box m="40px 0" height="75vh">
          <div className="table-responsive">
            <table className="table table-dark">
              <thead>
                <tr>
                  <th>Embassy Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Country</th>
                  <th>Note</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredEmbassy.length > 0 ? (
                  filteredEmbassy.map((embassy) => (
                    <tr key={embassy._id}>
                      <td>{embassy.embassyName}</td>
                      <td>{embassy.email}</td>
                      <td>{embassy.phoneNumber}</td>
                      <td>{embassy.countryId?.CountryName || "NA"}</td>
                      <td>{embassy.note}</td>
                      <td>
                        {new Date(embassy.date || Date.now()).toLocaleDateString()}
                      </td>
                      <td>{embassy.isActive ? "Active" : "Inactive"}</td>
                      <td>
                        <EditIcon
                          onClick={() => {
                            setCurrentEmbassy(embassy);
                            setOpenEditModal(true);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                        <DeleteIcon
                          onClick={() => {
                            setCurrentEmbassy(embassy);
                            setOpenDeleteModal(true);
                          }}
                          style={{ cursor: "pointer", color: "red" }}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center">
                      No Embassy available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Box>
  
        {/* Edit Modal */}
        <Modal open={openEditModal} onClose={() => setOpenEditModal(false)}>
          <Box sx={{ ...modalStyle }}>
            <DialogContent>
              <Typography variant="h6">Edit Embassy</Typography>
  
              <TextField
                label="Embassy Name"
                name="embassyName"
                value={currentEmbassy?.embassyName || ""}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
  
              <TextField
                label="Email"
                name="email"
                value={currentEmbassy?.email || ""}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
  
              <TextField
                label="Phone Number"
                name="phoneNumber"
                value={currentEmbassy?.phoneNumber || ""}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
  
              <TextField
                label="Note"
                name="note"
                value={currentEmbassy?.note || ""}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
  
              <TextField
                label="Date"
                type="date"
                name="date"
                value={
                  currentEmbassy?.date
                    ? new Date(currentEmbassy.date).toISOString().split("T")[0]
                    : ""
                }
                onChange={handleChange}
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
  
              <FormControl fullWidth variant="filled">
                <InputLabel id="passport-purpose-label">
                  Select Country Name
                </InputLabel>
                <Select
                  labelId="passport-purpose-label"
                  value={currentEmbassy?.countryId || ""}
                  onChange={(e) =>
                    setCurrentEmbassy((prev) => ({
                      ...prev,
                      countryId: e.target.value,
                    }))
                  }
                  name="country"
                >
                  {Array.isArray(countries) && countries.length > 0 ? (
                    countries.map((country) => (
                      <MenuItem key={country._id} value={country._id}>
                        {country.CountryName}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No countries available</MenuItem>
                  )}
                </Select>
              </FormControl>
  
              <Typography variant="subtitle1">Status</Typography>
              <RadioGroup
                row
                name="isActive"
                value={currentEmbassy?.isActive ? "active" : "inactive"}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="active"
                  control={<Radio />}
                  label="Active"
                />
                <FormControlLabel
                  value="inactive"
                  control={<Radio />}
                  label="Inactive"
                />
              </RadioGroup>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleUpdateEmbassy} color="primary">
                Save Changes
              </Button>
            </DialogActions>
          </Box>
        </Modal>
  
        {/* Delete Confirmation */}
        <Dialog open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to delete this Embassy?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleDeleteEmbassy(currentEmbassy?._id)}
              color="primary"
            >
              Yes
            </Button>
            <Button onClick={() => setOpenDeleteModal(false)} color="secondary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  };
  
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  
  export default Team;
  